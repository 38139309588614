@use 'partials' as *;

.componentStyle {
  position: fixed;
  top: 0;
  left: 50%;
  z-index: 999999;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;
  width: 5em;
  height: 2em;
  font-size: 1em;
  font-weight: bold;
  color: var(--color-white);
  letter-spacing: 0.2em;
  background-color: var(--color-primary);
  border-radius: 0 0 0.5em 0.5em;
  transform: translateX(-50%);
}
