@use 'partials' as *;

.serialCodeInput {
  display: flex;
  justify-content: center;

  input {
    display: block;
    width: 80%;
    height: size(40);
    text-align: center;
    text-transform: uppercase; // 入力時に見た目だけ大文字にする
    border: solid size(1) var(--color-pinkishgray);
  }
}
