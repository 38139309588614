@use 'partials' as *;

.siteNotifications {
  width: 100vw;
  padding: size(8);
  font-size: size(12);
  color: var(--color-white);
  text-align: center;
  background-color: var(--color-primary);

  // PC
  @include media-min(pc) {
    min-width: size($width-pc);
    padding: size(10);
    font-size: size(14);
  }
}
