@use 'partials' as *;

.fieldWrapper {
  padding: 0.5em 0;
  font-size: size(15);

  // PC
  @include media-min(pc) {
    font-size: size(18);
  }
}
