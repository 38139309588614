@use 'partials' as *;

.itemCaution {
  padding: size(12);
  background-color: var(--color-lightgray);
}

.textSerialCode {
  text-transform: uppercase; // 見た目だけ大文字にする
}

.contentWrapper {
  padding-bottom: size(20);
}

// PC
@include media-min(pc) {
  .itemCaution {
    padding: size(24);
  }

  .contentWrapper {
    padding-bottom: size(40);
  }
}
