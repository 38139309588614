@use 'partials' as *;

.componentStyle {
  text-align: center;
  background-color: var(--color-white);
}

.head {
  font-size: size(10);
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;
  padding: size(10) 0;
  text-align: center;
  width: 100%;
}

.bodyContainer {
  width: 100%;
  display: flex;
  justify-content: center;
}

.body {
  font-size: size(10);
  padding: size(10);
  text-align: left;
}

// PC
@include media-min(pc) {
  .head {
    font-size: size(14);
    padding: size(13) 0;
  }

  .body {
    font-size: size(12);
    width: size(1200);
  }
}
